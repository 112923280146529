import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { setPopup } from '../../../actions';
import { loader } from 'graphql.macro';


const query = loader('../../../graphql/getPopup.graphql');

const useGetPopup = ({ page = 'home' }) => {
	const dispatch = useDispatch();
	const { data } = useQuery(query, { variables: { page } });
	const popup = useSelector(store => store.popupReducer);

	const popupData = popup?.popup?.data;

	useEffect(() => {
		if (data?.getPopup) {
			const { getPopup } = data;
			dispatch(setPopup(getPopup));
		}
	}, [data, dispatch]);

	return {
		popupData: data?.getPopup || [],
	};
}

export default useGetPopup;