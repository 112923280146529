// Libraries
import { useEffect, useMemo, } from 'react';
import { useDispatch , useSelector } from "react-redux";

// Components
import Link from '../../../components/Link';
import PromoCodeInput from '../../../components/PromoСodeInput';
import { BasketItem } from './BasketItem';
import { Loader } from '../../../components/Loader';

// Helpers
import { formatPrice } from '../../../helpers/formatPrice';

// Hooks
import { useCart, useGetCommonPriceCart } from '../../../hooks';

// Actions
import { setPromoError, setCartPrice } from '../../../actions';

// Selectors
import { selectPromoError } from '../../../selectors/selectPromoError';

// Assets
import editIcon from '../../../assets/actions/edit.svg';

import styles from './Basket.module.scss';

export const FREE_DELIVERY_BORDER = 7000;

const getNoun = (count) => {
  const numbersFirstType = [1, 21];

  // позиции
  const numbersSecondType = [2, 3, 4, 22, 23, 24];

  return numbersFirstType.includes(count)
    ? 'позиция'
    : numbersSecondType.includes(count)
    ? 'позиции'
    : 'позиций';
};

export const Basket = ({ deliveryPrice = 0, deliveryInfo, fetchDeliveryPrice }) => {
  const dispatch = useDispatch();
  const promoError = useSelector(selectPromoError);
  const { products, loading: productsLoading } = useCart();
  const promoData = useSelector(store => store?.promoReducer?.data);

  const preparedProducts = useMemo(() => {
    return products.map(product => {
      const isDiscountItem = promoData && promoData?.coupon?.type === 'present' && promoData?.products?.includes(+product.id);
      const discount = promoData?.coupon?.discount;
      const option = product.productOptionValue;

      return ({
        ...product,
        price: option?.price ?? product.price ?? product.product_price,
        special_price: !promoError && isDiscountItem
          ? option?.price
            ? option.price * (1 - discount / 100)
            : product.price * (1 - discount / 100)
          : option?.special_price && option?.price !== option?.special_price
            ? option?.special_price
            : product?.special_price
      })
    })
  }, [products, promoData, fetchDeliveryPrice]);

  const { cartPrice, cartPriceWithoutDiscount, cartLength } = useGetCommonPriceCart(preparedProducts, promoData);

  const renderProducts = useMemo(() => {
    return preparedProducts.map((product) => <BasketItem fetchDeliveryPrice={fetchDeliveryPrice} key={product.product_id} {...product} />);
  }, [preparedProducts]);

  const renderDeliveryTitle = useMemo(() => {
    if (deliveryInfo?.type !== "pickup" && deliveryInfo?.courier_place === 'toDoor') {
      return 'Доставка до двери';
    }

    return 'Стоимость доставки';
  }, [deliveryInfo]);

  const hasDeliveryPrice = useMemo(() =>
    deliveryPrice > 0
    && parseInt(!promoError && promoData ? cartPrice : cartPriceWithoutDiscount) < FREE_DELIVERY_BORDER
  , [deliveryPrice, promoData, promoError, cartPrice, cartPriceWithoutDiscount]);

  const deliveryPriceTotal = useMemo(
    () => hasDeliveryPrice ? parseInt(deliveryPrice) : 0,
    [hasDeliveryPrice, deliveryPrice]
  );

  useEffect(() => {
    if(promoData?.coupon?.type === "sum" && cartPriceWithoutDiscount < parseInt(promoData?.coupon?.discount)) {
      dispatch(setPromoError(`Не хватает ${formatPrice(parseInt(promoData?.coupon?.discount) - cartPrice)} в корзине для применения купона`));
    } else if(promoData?.coupon?.type === "present" && parseInt(promoData?.coupon?.discount) > 100) {
      dispatch(setPromoError('Ошибка в купоне'));
    } else {
      if (promoData?.status) {
        dispatch(setPromoError(null));
      } else {
        dispatch(setPromoError(promoData?.message));
      }
    }

    if (promoError === 'Ошибка в купоне') {
      dispatch(setCartPrice(parseInt(cartPriceWithoutDiscount) + deliveryPriceTotal));
    } else {
      dispatch(setCartPrice(parseInt(cartPrice) + deliveryPriceTotal));
    }
  }, [promoData, cartPrice, cartPriceWithoutDiscount, deliveryPriceTotal, dispatch, promoError]);

  if(productsLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.Block}>
      <div className={styles.Basket}>
        <div className={styles.Basket__TopLine}>
          <div className={styles.Basket__Title}>Ваша корзина</div>
          <Link to="/basket">
            <img src={editIcon} alt="" />
          </Link>
        </div>
        <div className={styles.Basket__Items}>{renderProducts}</div>
        {
          deliveryPriceTotal > 0
          && (
            <div className={styles.Basket__Delivery}>
              <div className={styles.Basket__Delivery_Title}>{ renderDeliveryTitle }</div>
              <div className={styles.Basket__Delivery_Price}>{ deliveryPrice }</div>
            </div>
          )
        }
        {cartLength > 0 && (
          <div className={styles.Basket__Total}>
            <div className={styles.Basket__TotalText}>
              Итого, {cartLength} {getNoun(cartLength)}
            </div>
            <div className={styles.Basket__TotalPrice}>
              {!promoError && promoData && cartPrice !== cartPriceWithoutDiscount && (
                <span className={styles.Basket__TotalOldPrice}>
                {formatPrice(parseInt(cartPriceWithoutDiscount) + deliveryPriceTotal)}
              </span>
              )}
              <span>
              {promoError === 'Ошибка в купоне'
                ? formatPrice(parseInt(cartPriceWithoutDiscount) + deliveryPriceTotal)
                : formatPrice(parseInt(cartPrice) + deliveryPriceTotal)
              }
            </span>
            </div>
          </div>
        )}
        <PromoCodeInput
          products={products}
          value={products?.promoCode}
        />
        <div className={styles.PromoCodeInfo}>
          Промокод позволяет получить дополнительную скидку на некоторые товары. На билеты скидки не
          распространяются.
        </div>
      </div>
    </div>
  );
};
