import {useCallback, useState} from 'react';
import { createPortal } from 'react-dom';
import Page from '../../components/Page';
import Head from '../../components/Head';
import LoadContainer from '../../components/LoadContainer';
import {BANNER_VARIANTS, DEFAULT_BANNER_DATA, TICKETS} from '../../constants';
import { Loader } from '../../components/Loader';
import Excursion from '../../components/Excursion';
import { useGetExcursions } from '../../hooks/useGetExcursions';
import Banner from '../../components/Banner';
import Button from '../../components/Button';
import styles from './style.module.scss';


const Excursions = () => {
  const { excursions, loading } = useGetExcursions();
  const [bannerData, setBannerData] = useState(DEFAULT_BANNER_DATA);
  const renderExcursions = useCallback((item) => {
    return item && <Excursion key={item.id} data={item} />;
  }, []);
  console.log(bannerData)
  if (loading) {
    return <Loader style={{ margin: '125px 50%' }} />;
  }
  const bannerEl = document.getElementById('banner_popup');

  const changePopup =
    (variant) => () => {
      setBannerData({
        variant,
      });
    }

  const HEAD_TEXTS = {
    title: 'Экскурсии',
    text: (
      <>
        На этой странице вы можете приобрести аудио экскурсию по Государственному Эрмитажу. После покупки, экскурсия
        автоматически будет добавлена в ваш личный кабинет. Прослушать экскурсию можно в официальном приложении "Аудиогид по Эрмитажу":{' '}
        <a target='_blank' referrerPolicy='no-referrer' href="https://apps.apple.com/ru/app/%D0%B0%D1%83%D0%B4%D0%B8%D0%BE%D0%B3%D0%B8%D0%B4-%D0%BF%D0%BE-%D1%8D%D1%80%D0%BC%D0%B8%D1%82%D0%B0%D0%B6%D1%83/id880561085">Для iOS</a>{' / '}
        <a target='_blank' referrerPolicy='no-referrer' href="https://play.google.com/store/apps/details?id=com.dnateam.hermitage_ag">Для Android</a>. {' '}
        Пожалуйста, обратите внимание - билет на посещение музея приобретается отдельно.
        <br/>
        <br/>
        <div onClick={changePopup(BANNER_VARIANTS.about_excursion)}>
          <Button variant='contained'>Как использовать</Button>
        </div>
      </>
    )
  }
  console.log(bannerEl);
  return (
    <Page>
      {bannerEl && createPortal(<Banner {...bannerData} setBannerDataAlt={setBannerData} />, bannerEl)}
      {bannerData.variant !== BANNER_VARIANTS.about_excursion && (
        <>
          <Head {...HEAD_TEXTS} />

          <div className={styles.Excursions}>
            <LoadContainer
              component={renderExcursions}
              fetchRequest={() => null}
              type={TICKETS}
              items={excursions}
              gridSettings={{
                itemsPerRow: {
                  desktop: 3,
                  tablet: 2,
                  largeMobile: 1,
                  mobile: 1,
                },
                itemsPerPage: {
                  desktop: 15,
                  tablet: 15,
                  largeMobile: 15,
                  mobile: 15,
                },
              }}
            />
          </div>
        </>
      )}
    </Page>
  );
};

export default Excursions;
