// Libraries
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Page from '../../components/Page';
import ProductsHeader from '../../components/ProductsHeader';
import LoadContainer from '../../components/LoadContainer';
import ProductCard from '../../components/ProductCard';
import RowCategories from '../../components/RowCategories';
import { Loader } from '../../components/Loader';
import { EmptyContent } from '../../components/EmptyContent';

// Hooks
import { useProducts, useGetProducts, useFavorites } from '../../hooks';

// Constants
import { CATALOG } from '../../constants';

// Actions
import { setSortColumn, setSort, setKeyword } from '../../actions';

// Selectors
import { selectAllCategories, selectKeyword } from '../../selectors';

import styles from './style.module.scss';

const sortTypes = {
  popularity: {
    value: '',
    label: 'По популярности',
  },
  desc: {
    value: 'desc',
    label: 'По убыванию цены',
    sort_column: 'price'
  },
  asc: {
    value: 'asc',
    label: 'По возрастанию цены',
    sort_column: 'price'
  },
  without: {
    value: null,
    label: 'Без сортировки',
  },
};

const Catalog = () => {
  const [showFilters, setShowFilters] = useState(false);
  const dispatch = useDispatch();
  const { products, commonSize } = useProducts();
  const { getFavorites } = useFavorites();
  const {
    handlerGetProducts,
    loading,
    fetchMoreLoading
  } = useGetProducts();
	const keyword = useSelector(selectKeyword);
  const { category, subCategory } = useParams();
  const [selectedSortType, setSelectedSortType] = useState(sortTypes.without);
	const [chosenFilters, setChosenFilters] = useState([]);
	const [chosenFiltersIds, setChosenFiltersIds] = useState([]);
    const categories = useSelector(selectAllCategories);

  const mappedProducts = useMemo(() => products ? Array.from(products).map(([key, value]) => value) : [], [products]);

  useEffect(() => {
    setChosenFiltersIds([]);
    setChosenFilters([]);
  }, [category, subCategory]);

  const handleChangeChosenFilters = (data) => {
    setChosenFilters(data);
  }

  const handleChangeChosenFiltersIds = (data) => {
    setChosenFiltersIds(data);
  };

  const handleGetProducts = (data) => {
    const subCategoryData = categories
      ?.find((c) => (c?.seo_url && c.seo_url.toLowerCase().replace(/ /g, '_') === category) || c.name.toLowerCase().replace(/ /g, '_') === category)
      ?.children?.find((c) => (c?.seo_url && c.seo_url.toLowerCase().replace(/ /g, '_') === subCategory) || c.name.toLowerCase().replace(/ /g, '_') === subCategory);

    handlerGetProducts({
        filters: data?.filters,
        sort: data?.sortType?.value,
        sort_column: data?.sortType?.sort_column,
        keyword,
        isFetchMore: data?.isFetchMore || false,
        category: subCategoryData?.id
      });
  };

  useEffect(() => {
    if (categories.length && category && subCategory) {
      const currCategoryData = categories
      ?.find((c) => c.name.toLowerCase().replace(/ /g, '_') === category);
      const currSubCategoryData = currCategoryData?.children?.find((c) => c.name.toLowerCase().replace(/ /g, '_') === subCategory);

      handleGetProducts({ category: currSubCategoryData?.id });
    }
  }, [categories, category, subCategory]);

  useEffect(() => {
      handleGetProducts({ });
  }, [keyword])

  useEffect(() => {
      handleGetProducts();
      getFavorites();
  }, []);

  const handleChangeSelectSortType = (value) => {
    setSelectedSortType(value)
    handleGetProducts({ sortType: value })
  }

  const renderProductCard = (item) => {
    return item && <ProductCard item={item} category={category} subCategory={subCategory} />;
  };

  const onOpenFilters = () => {
    setShowFilters(true);
  };
  
  const onCloseFilters = () => {
    setShowFilters(false);
  };

  const onResetFilters = () => {
    handleGetProducts({ 
      filters: [], 
      sortType: { 
        value: null, 
        sort_column: null 
      } 
    });
    dispatch(setKeyword(null));
    setShowFilters(false);
    setChosenFilters([]);
    dispatch(setSort(null));
    dispatch(setSortColumn(null));
  }

  const onFetchMore = () => {
    handleGetProducts({ isFetchMore: true });
  };

  const renderContent = useMemo(() => {
    const StyledLoader = () => <Loader style={{ margin: '125px 50%' }} />;


    if (loading) {
      return <StyledLoader />;
    }

    if (mappedProducts.length > 0 && !loading) {
      return (
        <>
          <ProductsHeader
            onGetProducts={handleGetProducts}
            sortTypes={sortTypes}
            selectedSortType={selectedSortType}
            handleChangeSelectSortType={handleChangeSelectSortType}
            count={commonSize}
            keyword={!loading && keyword}
            chosenFilters={chosenFilters}
            categories={categories}
            showFilters={showFilters}
            handleChangeChosenFilters={handleChangeChosenFilters}
            handleChangeChosenFiltersIds={handleChangeChosenFiltersIds}
            onResetFilters={onResetFilters}
            onOpenFilters={onOpenFilters}
            onCloseFilters={onCloseFilters}
          />
          <LoadContainer
            type={CATALOG}
            component={renderProductCard}
            items={mappedProducts}
            loading={loading}
            fetchMore={onFetchMore}
            fetchMoreButton={!fetchMoreLoading}
          />
          {fetchMoreLoading && <StyledLoader />}
        </>
      );
    }

    if (mappedProducts.length === 0 && !loading) {
      return (
        <>
          <p className={styles.EmptyProducts}>{keyword ? `Ничего не найдено по запросу ${keyword}` : 'Ничего не найдено'}</p>
          <ProductsHeader 
            onGetProducts={handleGetProducts}
            sortTypes={sortTypes}
            selectedSortType={selectedSortType}
            handleChangeSelectSortType={handleChangeSelectSortType} 
            count={commonSize} 
            chosenFilters={chosenFilters} 
            categories={categories}
            showFilters={showFilters}
            handleChangeChosenFilters={handleChangeChosenFilters}
            handleChangeChosenFiltersIds={handleChangeChosenFiltersIds}
            onResetFilters={onResetFilters}
            onOpenFilters={onOpenFilters}
            onCloseFilters={onCloseFilters}
            />
          <EmptyContent onResetFilters={onResetFilters} type={CATALOG} />
        </>
      );
    }
  }, [loading, mappedProducts, selectedSortType, commonSize, keyword, chosenFilters, categories, showFilters, fetchMoreLoading, chosenFiltersIds]);

  return (
    <Page>
      <RowCategories />
      {renderContent}
    </Page>
  );
};

export default Catalog;
