// Libraries
import { useEffect, useContext } from 'react';
import classnames from 'classnames';

// Hooks
import { useDisableOverflow } from "../../hooks/useDisableOverflow"

// Components
import DeliveryAndPayment from './DeliveryAndPayment';
import MuseumItem from './MuseumItem';
import ChangePassword from './ChangePassword';
import RemindPassword from './RemindPassword';
import RemindSuccess from './RemindSuccess';
import CorporateSales from './CorporateSales';
import ReturnAndExchange from './ReturnAndExchange';
import ExcursionItem from './ExcursionItem';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Offer from './Offer';
import { Cookies } from './Cookies';
import { ProcessPersonalData } from './ProcessPersonalData';
import SuccesssPopup from './SuccessPopup';
import Policy from './Policy';

// Contexts
import { BannerContext } from '../Page';

// Constants
import { BANNER_VARIANTS, DEFAULT_BANNER_DATA } from '../../constants';

// Assets
import closeIcon from '../../assets/actions/close.svg';

// Styles
import styles from './style.module.scss';
import WarrantyAndQuality from './WarrantyAndQuality';
import AboutExcursion from './AboutExcursion';

const BANNER_COMPONENTS_MAP = (props = {}) => ({
	[BANNER_VARIANTS.delivery_and_payment]: <DeliveryAndPayment />,
	[BANNER_VARIANTS.museum_item]: <MuseumItem {...props} />,
	[BANNER_VARIANTS.excursion_item]: <ExcursionItem {...props} />,
	[BANNER_VARIANTS.change_password]: <ChangePassword {...props} />,
	[BANNER_VARIANTS.forgot_password]: <ForgotPassword {...props} />,
	[BANNER_VARIANTS.reset_password]: <ResetPassword {...props} />,
	[BANNER_VARIANTS.remind_password]: <RemindPassword {...props} />,
	[BANNER_VARIANTS.remind_success]: <RemindSuccess {...props} />,
	[BANNER_VARIANTS.corporate_sales]: <CorporateSales {...props} />,
	[BANNER_VARIANTS.cookies]: <Cookies />,
	[BANNER_VARIANTS.process_personal_data]: <ProcessPersonalData />,
	[BANNER_VARIANTS.offer]: <Offer />,
	[BANNER_VARIANTS.warranty_and_quality]: <WarrantyAndQuality />,
	[BANNER_VARIANTS.return_and_exchange]: <ReturnAndExchange />,
	[BANNER_VARIANTS.success_popup]: <SuccesssPopup {...props} />,
	[BANNER_VARIANTS.about_excursion]: <AboutExcursion {...props} />,
	[BANNER_VARIANTS.policy]: <Policy />
});

const Banner = ({ variant = null, data = null, setBannerDataAlt = () => null }) => {
	// useDisableOverflow(variant != null)
	//TODO: не сделал title, subtitle и form элементы в виде пропросов - надо переделать

	const setBannerData = useContext(BannerContext);

	useEffect(() => {
		variant && window.scrollTo(0, 0);
	}, [variant]);

	const onClose = () => {
		if (setBannerData) {
			setBannerData(DEFAULT_BANNER_DATA);
		}

		if (setBannerDataAlt) {
			setBannerDataAlt(DEFAULT_BANNER_DATA);
		}
	};

	return (
		variant && (
			<div className={classnames(styles.Banner, variant && styles.Banner_show)}>
				<div className={styles.Wrapper}>
					<div className={styles.Close} onClick={onClose}>
						<img src={closeIcon} alt="" />
					</div>
					<div className={styles.Content}>
						{BANNER_COMPONENTS_MAP({ data, onClose })[BANNER_VARIANTS[variant]]}
					</div>
				</div>
			</div>
		)
	);
};

export default Banner;
