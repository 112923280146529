// Components
import Button from '../../../components/Button';
import { Loader } from '../../../components/Loader';

// Hooks
import { useDeleteProductFromCart } from '../../../hooks';
import { useProduct } from "../../Product/hooks";

// Assets
import basketIcon from '../../../assets/actions/basket.svg';

import styles from './Basket.module.scss';
import {useSelector} from "react-redux";

export const BasketItem = ({ fetchDeliveryPrice, ...product }) => {
  const { category, quantity_selected, option_id, productId } = product;
  const { deleteProductFromCartHandler } = useDeleteProductFromCart();
  const { name, productLoading } = useProduct(productId);
  const promoData = useSelector(store => store?.promoReducer?.data);

  const currentOption = product?.productOptionValue || null;

  const price = currentOption?.price || product?.price;
  const special_price = currentOption?.special_price || product?.special_price;

  const couponPrice = promoData?.coupon
    && promoData.coupon?.discount
    && promoData?.products
    && promoData?.coupon?.type === 'present'
    && promoData?.products?.some(({ product_id, option_id }) =>
      (product?.product_id || product?.productId).toString() === product_id?.toString()
      && (product?.productOptionValue && option_id
        ? product.productOptionValue?.id?.toString() === option_id?.toString()
        : true
      )
    )
      ? (special_price ?? price) * (1 - promoData.coupon.discount / 100)
      : null;

  if (productLoading) {
    return <Loader style={{ marginTop: 20, marginBottom: 20 }} />;
  }

  const deleteHandler = () => {
    deleteProductFromCartHandler(product?.product_id || product?.productId, option_id);
    fetchDeliveryPrice();
  };

  const optionTitle = product?.productOptionValue?.name ? ` "${product.productOptionValue.name}"` : product?.option_name ? ` "${product?.option_name}"` : '';

  return (
    <div key={productId} className={styles.Basket__Item}>
      <div className={styles.Basket__Category}>{category}</div>
      <div className={styles.Basket__Col}>
        <div className={styles.Basket__Category}>
          {product?.product?.productToCategories[0]?.categoryDescriptions?.name ?? product?.productToCategories[0]?.name}
        </div>
        <div className={styles.Basket__Row}>
          <div className={styles.Basket__Name}>
            {product?.product?.productDescriptions?.name ?? name ?? product?.name}
            {optionTitle}
          </div>
          <div className={styles.Basket__Values}>
            <div className={styles.Basket__Count}>{quantity_selected} шт</div>
            <div className={styles.Basket__Price}>
              {Number(couponPrice ?? product?.special_price ?? (price ?? product.product_price)) || (
                <span className={styles.Basket__PriceFree}>0 ₽</span>
              )}
            </div>
            {/*{type !== BASKET_ITEM_TYPES.delivery && (*/}
            {/*  <Button inline className={styles.Basket__Remove} onClick={deleteProductFromCartHandler}>*/}
            {/*    <img src={basketIcon} />*/}
            {/*  </Button>*/}
            {/*)}*/}
            <Button inline className={styles.Basket__Remove} onClick={deleteHandler}>
              <img src={basketIcon} alt="" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
