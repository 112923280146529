// Libraries
import {useCallback, useEffect, useMemo, useState} from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// Assets
import PlusIcon from '../../assets/components/Plus';
import MinusIcon from '../../assets/components/Minus';
import SelectIcon from '../../assets/components/SelectArrow';

// Styles
import styles from './style.module.scss';
import Sticker from '../Sticker';


const MARKERS = {
  maths: {
    shown: <MinusIcon />,
    hidden: <PlusIcon />,
  },
  arrows: {
    shown: <SelectIcon rotated />,
    hidden: <SelectIcon />,
  },
};

const DropdownList = ({
  items = [],
  defaultOpenedItems = [],
  className,
  marker = 'arrows',
  compact,
  paddings = true,
  space,
  showOnlyOne,
  setHide = () => null,
  onChange = () => null
}) => {
  const [openedItems, setOpenedItems] = useState(defaultOpenedItems);
  
  const renderItemWithoutContent = useCallback((id, title) => {
    return (
      <div
        key={id}
        className={classnames(
          styles.Item,
          {[styles.Item_compact]: compact},
        )}
      >
        <Link
          to={`/catalog/${id}`}
          className={styles.TopPanel}
          onClick={setHide}
        >
          <div className={styles.Title}>
            {title}
          </div>
          {title === 'Скидки' && <Sticker />}
        </Link>
      </div>
    )
  }, [compact, setHide]);

  useEffect(() => onChange(openedItems), [openedItems]);

  const renderItems = useMemo(() => items.map(({ id, title, content, contentIsReactComponent, topPanel, icon }, index) => {
    const isOpened = openedItems.includes(index);

    if (!content.length && id) {
      return renderItemWithoutContent(id, title);
    }

    return (
      <div
        key={title}
        className={classnames(
          styles.Item,
          {
            [styles.Item_opened]: isOpened,
            [styles.Item_compact]: compact,
            [styles.Item_paddings]: isOpened && paddings,
            [styles.Item_space]: isOpened && space
          }
        )}
      >
        <div
          className={styles.TopPanel}
          onClick={() => {
            setOpenedItems((prev) =>
              isOpened
                ? prev.filter((item) => item !== index)
                : showOnlyOne
                  ? [index]
                  : [...prev, index],
            )
          }}
        >
          {topPanel || (
            <>
              <div className={styles.Title}>{title}</div>
              {icon && <div className={styles.Icon}>{icon}</div>}
            </>
          )}
          <div className={styles.Action}>
            {MARKERS[marker][isOpened ? 'shown' : 'hidden']}
          </div>
        </div>
        <div className={styles.Content}>
          {contentIsReactComponent
            ? <div dangerouslySetInnerHTML={{__html: content}} />
            : content}
        </div>
      </div>
    );
  }), [compact, items, marker, openedItems, paddings, renderItemWithoutContent, showOnlyOne, space]);

  return (
    <div className={className}>
      {renderItems}
    </div>
  );
};

export default DropdownList;
