import { Form } from 'react-final-form';
import { isEmptyObject } from '../../../helpers';
import { loginValidation } from '../helpers';
import { ERRORS_RU_TRANSLATIONS, BANNER_VARIANTS } from '../../../constants';
import { useLoginCustomer } from '../hooks';
import { ErrorPopup } from '../../../components/ErrorPopup';
import { Input } from '../../../components/Input/Input';
import Button from '../../../components/Button';
import { useContext } from 'react';
import { BannerContext } from '../../../components/Page';
import { Loader } from '../../../components/Loader';
import styles from '../style.module.scss';

export const Login = () => {
  const { handlerLoginUser, removeError, loading, error } = useLoginCustomer();
  const setBannerData = useContext(BannerContext);

  const handlerSetBannerData = () => {
    setBannerData({
      variant: BANNER_VARIANTS.forgot_password,
    });
  };

  const Content = () => (
    <>
      {error && <ErrorPopup text={ERRORS_RU_TRANSLATIONS[error] ?? error} handlerClose={removeError} />}
      <Form
        onSubmit={handlerLoginUser}
        validate={loginValidation}
        render={({ handleSubmit, submitting, pristine, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Input name={'email'} placeholder="E-mail" />
              <Input name={'password'} placeholder="Пароль" type="password" />
              <div
                className={styles.Link}
                onClick={handlerSetBannerData}
              >
                Я забыл пароль
              </div>
              {errors.length}
              <Button
                filled
                className={styles.Button}
                disabled={submitting || pristine || !isEmptyObject(errors)}
                htmlType='submit'
              >
                Войти
              </Button>
            </form>
          );
        }}
      />
    </>
  );

  return (
    <div className={loading && styles.Container}>
      {loading
        ? <Loader />
        : <Content />
      }
    </div>
  )
};
