// Libraries
import { useMemo } from 'react';
import classnames from 'classnames';

// Components
import Link from '../Link';
import { Price } from './Price';

// Hooks
import { useFavorites, useAddProductToCart, useIsProductInCart, useProductsHistory } from '../../hooks';

// Assets
import HeartIcon from '../../assets/components/Heart.js';

// Styles
import styles from './style.module.scss';

const ProductCard = ({ item, subCategory }) => {
  const { handlerFavorites, isFavorites, favorites } = useFavorites(item?.id);
  const { addProductToCartHandler } = useAddProductToCart(item?.id);
  const { isProductInCart } = useIsProductInCart(item?.id);
  const { addProductToHistory } = useProductsHistory(item?.id);
  const colorFavorites = isFavorites ? '#CF086E' : '#A7A8AA';
  const haveOptions =
    Array.isArray(item?.productOptionValues) && item.productOptionValues.length > 0;

  const minOptionPrice = useMemo(() => item
    && Array.isArray(item.productOptionValues)
    && item.productOptionValues.reduce((min, { special_price, price }) => {
      if (Number(price) < Number(min) && price) {
        return price;
      }

      return min;
    }, item.price)
  , [item]);

  const renderProductButton = useMemo(() => {
      const blockedStockStatus = ['нет в наличии', 'ожидание 2-3 дня', 'предзаказ']
          .includes(item?.stockStatus?.name && item?.stockStatus?.name.toLowerCase()) || !item?.stockStatus;

    if (item?.stockStatus?.name === 'Предзаказ' && !isProductInCart && !haveOptions && item?.id) {
      return (
        <button
          className={styles.Button}
          onClick={() => addProductToCartHandler({
            ...item,
            description: "",
            productId: parseInt(item.id),
            quantity: item.quantity,
            quantity_selected: 1,
          })}
        >
          Предзаказ
        </button>
      )
    }

    if (item?.stockStatus?.name === 'Предзаказ' && !isProductInCart && haveOptions) {
      return (
        <Link to={`/product/${item.id}`} className={styles.Button}>
          Предзаказ
        </Link>
      )
    }

    if (item?.id && isProductInCart) {
      return (
        <Link to='/basket'>
          <button className={styles.Button}>
            Оформить заказ
          </button>
        </Link>
      );
    }

    if (
      blockedStockStatus &&
      haveOptions
        ? item.productOptionValues.every(({ quantity }) => quantity === 0)
        : item.quantity === 0
    ) {
      return (
        <button
          className={styles.Button}
          disabled
        >
          Нет в наличии
        </button>
      );
    }

    if (
      item?.id
      && !isProductInCart
      && haveOptions
    ) {
      return (
        <Link to={`/product/${item.id}`} className={styles.Button}>
          Выбрать опции
        </Link>
      );
    }

    if (item?.id) {
      return (
        <button
          className={styles.Button}
          onClick={() => addProductToCartHandler({
            ...item,
            description: "",
            productId: parseInt(item.id),
            quantity: item.quantity,
            quantity_selected: 1,
          })}
        >
          В корзину
        </button>
      )
    }

    return null;
  }, [item, haveOptions, isProductInCart, addProductToCartHandler]);

  const handleClick = () => {
    addProductToHistory(subCategory)
  };

  return item ? (
    <div className={styles.ProductCard} onClick={handleClick}>
      <Link
        to={`/product/${item.id}${subCategory ? `?subCategory=${subCategory}`: ''}`}
        exact
        className={styles.Link}
      >
      {/* <Link to={`/product/${item.id}?category=${category}&subCategory=${subCategory}`} exact className={styles.Link}> */}
        <div className={styles.Preview}>
          <div
            className={classnames(
              styles.Image,
              // fade ? styles.Image_fade : styles.Image_show,
            )}
          >
            <img src={item.image} alt='' />
          </div>
          <div className={styles.Liked} onClick={handlerFavorites(Number(item?.id))}>
            <HeartIcon fill={colorFavorites} isFilled={isFavorites} />
          </div>
        </div>
        <div className={styles.Wrapper}>
          <div className={styles.Name}>
            {item?.name ?? item?.productDescriptions?.name ?? null}
          </div>
        </div>
      </Link>
      <div className={styles.Wrapper}>
        <div className={styles.Price}>
          <Price
            {...item}
            price={haveOptions ? minOptionPrice : item.price}
            special_price={haveOptions 
              ? item.productOptionValues?.filter(i => parseInt(i.price) === parseInt(minOptionPrice))[0]?.special_price 
              : item.special_price}
            template={haveOptions ? 'От {price}' : '{price}'}
            quantity={
              item?.productOptionValues?.length > 0
                ? item.productOptionValues.reduce((sum, { quantity }) => sum + quantity, 0)
                : item.quantity_selected
            }
          />
        </div>
        {renderProductButton}
      </div>
    </div>
  ) : null;
};

export default ProductCard;
