import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../../assets/actions/close.svg';
import styles from './style.module.scss';

const Policy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className={styles.Banner}>
      <div className={styles.Wrapper}>
        <div className={styles.Content}>
          <Link to='/'><img src={closeIcon} alt='' /></Link>
          <div className={styles.Heading}>
            ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ДЛЯ МОБИЛЬНОГО ПРИЛОЖЕНИЯ "АУДИОГИД ПО ЭРМИТАЖУ"
          </div>
          <div className={styles.Heading}>1. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</div>
          <p>
            "Закон" означает Федеральный закон Российской Федерации "О персональных данных" со всеми изменениями и
            дополнениями,
            а также иные законодательные акты Российской Федерации. "Мобильное приложение" это программное обеспечение
            (со всеми существующими дополнениями и улучшениями), предназначенное для работы на смартфонах, планшетах,
            часах и других мобильных устройствах, и разработанное для конкретной платформы (iOS, Android и т. д.).
            Для целей настоящей Политики под Мобильным приложением подразумевается следующее программное обеспечение:
            АУДИОГИД ПО ЭРМИТАЖУ. "Персональные данные" означает совокупность личных данных и/или неперсонифицированной
            информации о Пользователе, предоставляемую им самим Правообладателю и/или автоматически собираемую
            Правообладателем и/или третьими лицами. "Политика" означает настоящею Политику конфиденциальности
            мобильного приложения (со всеми существующими дополнениями и изменениями). "Пользователь" означает
            юридическое или физическое лицо, которое загрузило Мобильное приложение на смартфон, планшет, часы или любое
            другое мобильное устройство и/или осуществило активацию такого Мобильного приложения на одном из указанных
            устройств. "Пользовательское соглашение" означает соглашение, заключаемое между Правообладателем и
            Пользователем
            в отношении порядка, правил и особенностей использования Пользователем Мобильного приложения. Пользователь
            присоединяется к такому соглашению и не имеет права вносить и/или требовать внесения в него какихлибо
            изменений или дополнений. Пользователь может ознакомиться с условиями Пользовательского соглашения по
            следующей ссылке: https://www.hermitageshop.ru/ warranty. "Правообладатель" означает следующее лицо,
            которому
            принадлежат исключительные права владения Мобильным приложением ООО «Артеком», ИНН/КПП 7814121367/78400100,
            191186, г. Санкт-Петербург, Дворцовая пл., д.6-8, литер А, ч.пом.1-Н, ч.п. 205 "Файлы куки" означает
            небольшие
            файлы, отправляемые каким-либо мобильным приложениям или сайтом, и размещаемые на смартфонах, планшетах,
            часах
            и других мобильных устройствах Пользователя, для улучшения работы таких приложений или сайтов, а также
            качества
            размещенного в них контента.
          </p>
          <div className={styles.Heading}>2. ОТНОШЕНИЯ, НА КОТОРЫЕ РАСПРОСТРАНЯЕТСЯ ПОЛИТИКА</div>
          <p>
            Страница 1 из 12 Общие положения Данная Политика используется и применима исключительно к Персональным
            данным,
            получаемым от Пользователя в связи с использованием им Мобильного приложения. Положения данной Политики
            направлены на: (1) определение видов и типов получаемых Персональных данных, направлений и целей
            использования
            (обработки) Персональных данных, а также источников получения таких Персональных данных; и (2) определение
            прав
            Пользователя в отношении защиты конфиденциальности передаваемых им Персональных данных; и (3) определение
            лиц,
            ответственных за обработку и хранение Персональных данных, а также третьих лиц, которым такие данные
            раскрываются (полностью или частично). Правила настоящей Политики не применяются в случае обработки третьими
            лицами Персональных данных, которые добровольно предоставляются Пользователем. Посредством установки и/или
            активации Мобильного приложения на смартфоне, планшете, часах или другом мобильном устройстве Пользователь
            соглашается с условиями данной Политики и дает свое согласие Правообладателю на сбор, обработку, удержание и
            хранение Персональных данных в порядке и на условиях, предусмотренных настоящей Политикой. Если Пользователь
            не
            согласен с условиями Политики и/или отдельные условия Политики ему не понятны, в таком случае Пользователь
            обязан немедленно прекратить использование Мобильного приложения. Права пользователя по защите персональных
            данных В связи с предоставлением Персональных данных Пользователь автоматически получает следующие права:
            (1)
            получать данные, касающиеся их обработки (основания и цели такой обработки, применяемые способы обработки,
            сведения о лицах, которые имеют доступ к ним или которым они могут быть раскрыты на основании договора или
            Закона). (2) получать данные о месте нахождения и идентификационных данных лиц, совершающих обработку
            Персональных данных. (3) получать данные о сроках хранения Персональных данных. (4) получать данные об
            осуществленной или о предполагаемой трансграничной передаче Персональных данных. (5) получать информацию о
            месте
            нахождения и идентификационных данных лиц, совершающих хранение Персональных данных. (6) обжаловать действия
            или
            бездействие Правообладателя в уполномоченный орган по защите прав субъектов персональных данных или в
            судебном
            порядке. Страница 2 из 12 (7) получать возмещение убытков и/или компенсацию морального вреда в судебном
            порядке
            в следствие допущенных Правообладателем и/или третьими лицами нарушений прав Пользователя на охрану и защиту
            его
            Персональных данных. (8 реализовывать иные права в области защиты персональных данных, предусмотренные
            Законом
            или положениями данной Политики.
          </p>
          <div className={styles.Heading}>3. ПЕРЕЧЕНЬ СОБИРАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
          <p>
            Неперсонифицированная информация о пользователях В связи с использованием Мобильного приложения
            Правообладатель
            может автоматически собирать и обрабатывать следующею неперсонифицированную информацию о Пользователе: (1)
            информацию о трафике, возможном количестве совершенных кликов, логи и другие данные. (2) информацию об
            устройстве (идентификационный номер, сеть мобильного оператора), с которого выполняется вход, операционная
            система, платформа, тип браузера и другая информация о браузере, IP адрес. Личные данные о пользователях
            Пользователь предоставляет Правообладателю о себе следующие личные данные: (1) полные фамилию, имя и
            отчество.
            (2) адрес электронной почты. (3) номер мобильного телефона. (4) данные из следующей социальной сети:
            Vkontakte.
            Правообладатель может собирать, обрабатывать и хранить ID Пользователя любой из социальных сетей, которая
            была
            использована Пользователем в рамках Мобильного приложения. Если регистрация Пользователя в Мобильном
            приложении
            осуществляется с помощью одного из его аккаунтов в социальных сетях, Пользователь тем самым предоставляет
            Правообладателю автоматическое согласие на сбор, обработку и хранение Персональных данных, которые стали
            доступны Правообладателю через выбранную социальную сеть. (5) все фотографии, видео клипы, аудио файлы и
            другие
            виды медиа, которые хранятся на устройстве, с которого выполняется вход в Мобильное приложение. (6) данные,
            которые содержатся в личном кабинете (профиле) Пользователя, все внутренние переписки Пользователя (если
            есть),
            а также иная активность личного кабинета (профиля) Пользователя. (7) данные о совершенных Пользователям
            заказах/покупках и/или полученных/ оплаченных услугах через Мобильное приложение. Страница 3 из 12 (8)
            данные
            о
            всех совершенных Пользователем публикациях в Мобильном приложении, включая, однако не ограничиваясь,
            комментарии, выставление оценок, отзывы, публикация отчетов, видео и фотографии, проставление лайков,
            рейтинги
            и/или какие-либо другие формы активности, доступные Пользователю в Мобильном приложении, и/или создаваемом
            контенте. (9) данные и информация, получаемая в результате объединения тех или иных Персональных данных
            конкретного Пользователя, а также данные и информация, получаемые данные о Пользователе, получаемые от
            третьих
            лиц (партнеров, маркетологов, исследователей). Пользователь является единственным ответственным лицом за
            полноту
            предоставляемых личных (персональных) данных и обязан осуществлять своевременное их изменение (обновление,
            проверку, корректировку) на регулярной основе. Правообладатель исходит из того, что все предоставленные
            Пользователем личные (персональные) данные являются достоверными, и что Пользователь поддерживает такую
            информацию в актуальном состоянии. Информация о совершаемых транзакциях Пользователь через Мобильное
            приложение
            может осуществлять оплату за товары или услуги, посредством введения в специальное поле информации о
            платежной
            карте и идентификационных данных собственника такой карты. Пользователь может совершать оплату в Мобильном
            приложении следующим способом: • посредством банковской карты. • с помощью платежной системы Pay Pal. • с
            помощью платежной системы Apple Pay. • с помощью платежной системы Google Pay. • с помощью платежной системы
            ООО
            НКО «Твои Платежи» (только для Android). • а также следующим способом: Со счета мобильного оператора. Сбор и
            обработка данных о Пользователе в данном случае осуществляется исключительно для целей проведения оплаты,
            недопущения мошенничества, а также соблюдения иных требований Закона. Пользователь дает свое согласие на
            доступ
            и сбор Правообладателем и соответствующей платежной системой или банковским учреждением, через которую/
            которое
            проводится оплата, к таким Персональным данным, а также соглашается с политикой конфиденциальности
            соответствующей платежной системы или банковского учреждения. Использование файлов куки Данное Мобильное
            приложение применяет определенные Файлы куки для сохранения IP-адреса, предпочтений Пользователей или типа
            используемого устройства с целью (1) ведения статистики посещений и трафика сайта, и (2) персонализации
            выводимых на экран Пользователя данных, и (3) сохранения данных, необходимых для идентификации Пользователя,
            в
            том числе при доступе с разных устройств, и (4) показа рекламы в Страница 4 из 12 соответствии с интересами
            и
            предпочтениями Пользователя. Мобильное приложение может использовать как собственные Файлы куки,
            принадлежащие
            Правообладателю, так и Файлы куки третьих лиц. Мобильное приложение использует следующие Файлы куки: (1)
            Технические (функциональные) Файлы куки, которые нужны для контроля трафика и передачи данных, для
            идентификации
            Пользователей и предоставления доступа Пользователю к контенту Мобильного приложения и без которых
            использование
            Мобильного приложения является функционально ограниченным, а также для предотвращения предоставления
            рекомендаций, не соответствующих интересам Пользователя. (2) Статистические Файлы куки, которые нужны для
            отслеживания частоты посещаемости сайта Пользователями, для выявления способов использования Пользователем
            Мобильного приложения, а также для выявления типа и вида контента, который является популярными или
            интересным
            для Пользователя. (3) Рекламные (маркетинговые) Файлы куки, которые нужные для размещения рекламных и/или
            маркетинговых объявлений в Мобильном приложении, которые соответствуют предпочтениями и интересам
            Пользователя.
            (4) Файлы куки третьих лиц, которые устанавливаются третьими лицами с разрешения Пользователя и
            предназначены
            для проведения статистических исследований, касающихся поведения Пользователя в сети Интернет и/или
            направления
            персонализированных рекламных или маркетинговых материалов Пользователю и/или предоставления товаров или
            услуг.
            Пользователь имеет право в любой момент отключить Файлы куки в Мобильном приложении путем изменения
            определенных
            настроек в своем смартфоне, планшете, часах или другом мобильном устройстве. Такое отключение может повлечь
            за
            собой ограничение или изменение доступа Пользователя к функциональным возможностям Мобильного приложения
            и/или
            контенту. Для отключения Файлов куки нужно выполнить следующие действия: Перейти в «Настройки» мобильного
            устройства.
          </p>
          <div className={styles.Heading}>4. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
          <p>
            Определение целей обработки Сбор и обработка Персональных данных осуществляется в следующих целях: (1) для
            анализа поведения Пользователя, а также выявления предпочтений Пользователя к определенному виду контента.
            (2)
            для оперативной и корректной работы Мобильного приложения, улучшения функционирования работы Мобильного
            приложения, улучшения контента Мо б ил ь н о го п р ил оже н и я , ул у чш е н и я в н у т р е н н е й а р х
            и
            т
            ект у ры и функциональности Мобильного приложения. Страница 5 из 12 (3) для идентификации Пользователя. (4)
            для
            предоставления персонализированных рекламных и маркетинговых материалов. (5) для направления
            персонализированных
            рекламных и маркетинговых материалов на указанный электронный адрес и/или мобильный телефон Пользователя.
            (6)
            для соблюдения требований Закона. (7) для отслеживания заказов/покупок, совершенных Пользователем через
            Мобильное приложение. (8) для технической поддержки Мобильного приложения, выявления проблем в его работе и
            их
            устранение. (9) для поддержания связи с Пользователем (коммуникация). (10) для выполнения иных обязательств
            Правообладателя, которые возникли перед Пользователем. (11) для проведения статистических исследований. (12)
            для
            любых других целей, при условии получения отдельного согласия от Пользователя. Обработка Персональных данных
            осуществляется на основе принципов: (1) законности целей и способов обработки; и (2) добросовестности; и (3)
            соответствия целей обработки Персональных данных целям, заранее определенным и заявленным при сборе таких
            Персональных данных; и (4) соответствия объема и характера обрабатываемых Персональных данных заявленным
            целям
            их обработки. Условия обработки персональных данных Обработка Персональных данных проводится в случаях: (1)
            получения согласия от Пользователя; или (2) достижения Правообладателем целей, предусмотренных международным
            договором или Законом; или (3) предоставления Пользователем своих Персональных данных неограниченному кругу
            лицу; или (4) выполнения иных обязательств Правообладателя перед Пользователем, включая, однако не
            ограничиваясь, предоставление определенного контента Пользователю; или (5) спасения жизни или здоровья
            Пользователя, когда согласие на обработку его Персональных данных не удается получить заблаговременно. В
            случае
            обезличивания Персональных данных, что не позволяет прямо или опосредованно определить Пользователя,
            последующее
            использование и раскрытие таких данных третьим лицам допускается и в отношении их более не применяются
            правила
            данной Политики. Правообладатель принимает все возможные меры для защиты конфиденциальности полученных
            Персональных данных, за исключением случаев, когда Пользователь сделал такие данные общедоступными. Страница
            6
            из 12 Обработка Персональных данных осуществляется с использованием средств автоматизации и без
            использования
            таких средств автоматизации.
          </p>
          <div className={styles.Heading}>5. ДОСТУП ТРЕТЬИХ ЛИЦ К ПЕРСОНАЛЬНЫМ ДАННЫМ</div>
          <p>
            Использование ремаркетинговых сервисов Правообладатель использует ремаркетинг для рекламы Пользователю
            контента
            Мобильного приложения на других сайтах, посещаемых Пользователем. Ремаркетинговые сервисы Правообладателю
            предоставляются посредством платформы Google ads. Google Ads собирает и обрабатывает неперсонифицированные
            данные, которые напрямую не позволяют установить или идентифицировать Пользователя. Собираемая информация,
            как
            правило, может включать (1) контент, который просматривал Пользователь, (2) дату и время, когда Пользователь
            просматривал контент, (3) данные о геолокации. Сбор и обработка такой неперсонифицированной информации
            позволяет
            предоставлять Пользователю более таргетинговый рекламный или маркетинговый контент. Посредством установки
            Мобильного приложения Пользователь соглашается с политикой конфиденциальности (Privacy Policy) для Google
            Ads
            и
            Пользовательским соглашением (Terms of Use) для Google Ads, а также с автоматической установкой на
            устройство
            Пользователя соответствующих Файлов куки. Пользователь имеет право в любой момент отказаться от такой
            рекламы
            посредством изменений соответствующих настроек браузера и устройства, с которого совершается вход в
            Мобильное
            приложение. Использование аналитических платформ Правообладатель использует аналитическую платформу Google
            Analytics для (1) отслеживания частоты посещаемости сайта Пользователями; и (2) отслеживания способов
            использования Пользователем Мобильного приложения и/или его контента; и (3) выявления типа и вида контента,
            который является популярными среди Пользователей; и (4) определения места нахождения Пользователя.
            Пользователь
            также дает свое согласие Правообладателю на использование им информации, полученной о Пользователе от Google
            Analytics. Для указанных целей аналитическая платформа Google Analytics может собирать данные об IP адресе,
            геолокации, поведении Пользователя, а также его предпочтениях и интересе в отношении определенного контента.
            Аналитическая платформа Google Analytics получает доступ к Персональным данным с тем, чтоб предоставить
            Правообладателю понимание того насколько эффективно работает его Мобильное приложение, какой именно контент
            является популярным, насколько эффективно размещение в нем той или иной рекламы, а также для целей
            разработки
            и/или улучшения суще ствующей маркетинговой ст ратегии Правообладателя. Посредством установки Мобильного
            приложения Пользователь соглашается с политикой конфиденциальности (Privacy Policy) Google Analytics, а
            также
            с
            Страница 7 из 12 автоматической установкой на устройство Пользователя соответствующих Файлов куки. Раскрытие
            персональных данных третьим лицам Правообладатель имеет право раскрывать Персональные данные (1) своим
            аффилированным лицам, филиалам и представительствам, открытым как на территории Российской Федерации, так и
            на
            территории других государств; (2) правопреемникам Правообладателя, которые возникли в результате его
            ликвидации,
            реорганизации или банкротства, и которые получили исключительные права владения Мобильным приложением; (3)
            поставщикам платежных услуг или банковским (финансовым) учреждениям, для проведения транзакций Пользователя
            через Мобильное приложение; (4) третьим лицам исключительно для целей оказания получения Пользователем
            определенного контента или доступа к нему; (5) третьим лицам, когда Пользователем было дано согласие на
            раскрытие, передачу или обработку своих Персональных данных, а также в иных случаях, прямо предусмотренных
            Законом или данной Политикой. Правообладатель раскрывает Персональные данные только в том случае, если (1)
            уверен, что третьи лица будут соблюдать условия данной Политики и предпринимать такие же меры по защите
            конфиденциальности Персональных данных, которые предпринимает сам Правообладатель, и (2) согласие на такое
            раскрытие было предварительно выражено Пользователем и/или допускается на основании Закона. Реклама от
            третьих
            лиц Контент Мобильного приложения может содержать рекламные баннеры и/или ссылки на сайты третьих лиц.
            Использование Пользователем таких сайтов (путем перехода по ссылке или любым другим способом) может повлечь
            за
            собой сбор, обработку и использование Персональных данных, а также возможную автоматическую передачу Файлов
            куки
            на устройство Пользователя, с которого совершается переход на сайт третьих лиц. Правообладатель не несет
            какой-либо ответственности за способы, методы и порядок обработки Персональных данных сайтами третьих лиц. В
            следствие чего Правообладатель также не является ответственным лицом в случае раскрытия Персональных данных
            неограниченному кругу лиц в связи с использованием Пользователем таких сайтов. Правообладатель настоятельно
            рекомендует каждому Пользователю детально ознакомиться с политиками защиты персональных данных используемых
            сайтов. Пользователь имеет право в любой момент отключить такие рекламные баннеры и/или ссылки посредством
            выполнения следующих действий: Перейти в «Настройки» приложения в личном аккаунте.
          </p>
          <div className={styles.Heading}>6. РАЗМЕЩЕНИЕ РЕКЛАМЫ</div>
          <p>
            Реклама в мобильном приложении Страница 8 из 12 Правообладатель вместе с контентом размещает в Мобильном
            приложении различные рекламные и маркетинговые материалы с учетом выявленных предпочтений Пользователя к
            тому
            или иному контенту. Размещение рекламы в Мобильном приложении предполагает установку на устройстве
            Правообладателя определенных Файлов куки. Пользователь имеет право в любой момент отказаться от такой
            рекламы
            посредством выполнения следующих действий: Перейти в «Настройки» приложения в личном аккаунте. Рассылка
            рекламных материалов Пользователь автоматически с установкой Мобильного приложения на устройство соглашается
            с
            правом Правообладателя отправлять на предоставленный электронный адрес и/или мобильный телефон
            персонализированные рекламные и маркетинговые материалы. Пользователь имеет право в любой момент отказаться
            от
            получения таких рекламных и маркетинговых материалов посредством выполнения следующих действий: Направить
            соответствующее письмо об отказе на электронную почту: info@hermitageshop.ru Обращаем внимание, что в случае
            отказа от получения рекламных и маркетинговых материалов, Пользователь может продолжать получать от
            Правообладателя какие-либо уведомления, связанные с порядком использования Мобильного приложения и/или его
            контента.
          </p>
          <div className={styles.Heading}>7. НАПРАВЛЕНИЕ ЖАЛОБ И ЗАПРОСОВ ПРАВООБЛАДАТЕЛЮ</div>
          <p>
            Требование о прекращении обработки персональных данных Каждый Пользователь имеет право выразить свое
            возражение
            Правообладателю против обработки и/или хранения его Персональных данных. Такое возражение может быть
            выражено
            следующим образом: Запрос должен быть направлен Правообладателю на следующий адрес: info@hermitageshop.ru
            Запрос
            на получение информации о персональных данных Если у Пользователя возникают вопросы, связанные с порядком
            применения или использования настоящий Политики, порядком и/или способом обработки Персональных данных,
            Пользователь может задать такой вопрос следующим образом: Вопрос должен быть направлен Правообладателю на
            следующий адрес: info@hermitageshop.ru Изменение (обновление, дополнение, корректировка) или удалении
            персональных данных Страница 9 из 12 Пользователь имеет право в любой момент самостоятельно изменить или
            удалить
            свои Персональные данные, за исключением случаев, когда такое изменение или удаление может привести (1) к
            нарушению правил настоящей Политики; или (2) к нарушению Закона; (3) характер таких Персональных данных
            является
            доказательством в какомлибо судебном процессе, возникшем между Правообладателем и Пользователем. Для этого
            Пользователю требуется удалить свой личный аккаунт (профиль) в Мобильном приложении. Правообладатель имеет
            право
            в любой момент удалить личный аккаунт/профиль Пользователя, а также все Персональные данные о Пользователе,
            если
            он нарушил условия данной Политики и/или Пользовательского соглашения. В случае удаления Персональных данных
            о
            Пользователе, автоматическому удалению подлежат также все совершенные таким Пользователем публикации
            (комментарии, выставление оценок, отзывы, публикация отчетов, видео и фотографии, проставление лайков,
            рейтинги)
            и/или какие-либо другие формы активности, доступные Пользователю в Мобильном приложении. Для удаления
            персональных данных пользователя свяжитесь с Нами по почте: info@hermitageshop.com. Запрос будет обработан в
            течение 3 рабочих дней. После удаления персональных данных о пользователе Мы направим подтверждение, ответив
            на
            письмо с запросом.
          </p>
          <div className={styles.Heading}>8. СРОКИ И ПОРЯДОК ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
          <p>
            Хранение осуществляется третьими лицами по поручению Правообладателя. Пользователь дает свое согласие на
            хранение его Персональных данных третьими лицами по поручению Правообладателя, при условии сохранения такими
            третьими лицами конфиденциальности полученных Персональных данных. Функции хранения Информации поручены
            следующему лицу: ООО "АРТЕКОМ" (далее "Хранитель"). Хранение Персональных данных осуществляется на
            территории
            Российской Федерации. Хранение осуществляется в течение всего срока, необходимого для достижения заявленных
            целей обработки Персональных данных. Правообладатель обязуется немедленно после достижения целей обработки
            Персональных данных уничтожить их или же обезличить.
          </p>
          <div className={styles.Heading}>9. ДОСТУПНОСТЬ НЕСОВЕРШЕННОЛЕТНИХ К МОБИЛЬНОМУ ПРИЛОЖЕНИЮ</div>
          <p>
            Мобильное приложение может быть использовано лицами в возрасте до 18 лет. Мобильное приложение собирает
            личные
            данные о Пользователях, потому использование настоящего приложения несовершеннолетними Пользователями
            допускается только при условии получения предварительного согласия от законного Страница 10 из 12
            представителя
            (опекуна) на обработку Персональных данных. Такое согласие должно быть предоставлено следующим способом:
            Направление скана паспорта законного представителя на электронную почту: info@hermitageshop.ru Если
            несовершеннолетний Пользователь не может предоставить согласие на обработку его Персональных данных от
            законного
            представителя (опекуна), в таком случае такой Пользователь обязан немедленно прекратить использование
            Мобильного
            приложения.
          </p>
          <div className={styles.Heading}>10. ПОРЯДОК ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</div>
          <p>
            Защита конфиденциальности Персональных данных является первостепенной и важной задачей для Правообладателя.
            Правообладатель придерживается всех требуемых международных стандартов, правил и рекомендаций по защите
            Персональных данных. Правообладатель внедрил ряд технических и организационных методов, направленных на
            защиту
            Персональных данных от разглашения или несанкционированного доступа к ним третьих лиц. Для обеспечения
            сохранности и конфиденциальности получаемых Персональных данных Правообладатель использует следующие
            средства
            защиты: (1) SSL (Security Sockets Layer) протокол. (2) автоматическое сохранение данных. (3) а также: (1)
            установка пароля
          </p>
          <div className={styles.Heading}>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</div>
          <p>
            Доступность текста политики для ознакомления Пользователи могут ознакомиться с условиями данной Политики по
            следующей ссылке: https://www.hermitageshop.ru/warranty. Данная редакция Политики действует от 1 января 2022
            годa. Изменение и дополнение политики Настоящая Политика может быть изменена время от времени.
            Правообладатель
            не несет какой-либо ответственности перед Пользователем за изменение условий данной Политики без разрешения
            и/или согласия Пользователя. Пользователь сам обязуется на регулярной основе проверять положения данной
            Политики
            на предмет ее возможного изменения или дополнения. Применимое законодательство Настоящая Политика
            разработана
            в
            соответствие с действующим законодательством о защите персональных данных Российской Федерации, в частности,
            с
            нормами Страница 11 из 12 Федерального закона от 27 июля 2006 года № 152-ФЗ "О персональных данных" (со
            всеми
            дополнениями и изменениями), Федерального закона от 21 июля 2014 года № 242-ФЗ "О внесении изменений в
            отдельные
            законодательные акты Российской Федерации в части уточнения порядка обработки персональных данных в
            информационно-телекоммуникационных сетях" (со всеми дополнениями и изменениями). Риск разглашения Вне
            зависимо
            сти от предпринимаемых Правообладателем мер защиты конфиденциальности получаемых персональных данных,
            Пользователь настоящим считается должным образом ознакомлен с тем, что любая передача Персональных данных в
            сети
            Интернет не может быть гарантировано безопасной, а потому Пользователь осуществляет такую передачу на свой
            собственный риск. Публичная информация В рамках Мобильного приложения Пользователь имеет право осуществлять
            публикации и размещать любой контент по своему собственному усмотрению и в любой из доступных форм (фото,
            видео,
            комментарий, статья, оценка, блог и т.п.). Такие публикации и контент являются общедоступными для других
            пользователей Мобильного приложения, в связи с чем Правообладатель не берет на себе какие-либо обязательства
            по
            защите Персональных данных, которые могут быть обнародованы или опубликованы в рамках такой публикации и/или
            контента. Свяжитесь с нами Если у вас есть какие-либо вопросы по поводу настоящей Политики
            конфиденциальности,
            вы можете связаться с нами: * По электронной почте: info@hermitageshop.ru * Посетив эту страницу на нашем
            сайте:
            <a href='www.hermitageshop.ru'> www.hermitageshop.ru</a>
          </p>
          <div className={styles.Heading}>PRIVACY POLICY FOR THE MOBILE APP "AUDIO GUIDE.HERMITAGE"</div>
          <div className={styles.Heading}>1. DEFINITION OF CONCEPTS</div>
          <p>
            "Law" means the Federal Law of the Russian Federation "On Personal Data" with all amendments and additions,
            as
            well as other legislative acts of the Russian Federation. "Mobile Application" is software (with all
            existing
            additions and improvements) designed to work on smartphones, tablets, watches and other mobile devices, and
            developed for a specific platform (iOS, Android, Windows Phone, etc.). For the purposes of this Policy, a
            Mobile
            Application means the following software: AUDIOGUIDE to the Hermitage. "Personal Data" means a set of
            personal
            data and/or non-personalized information about the User provided by him to the Copyright Holder and/or
            automatically collected by the Copyright Holder and/or third parties. "Policy" means the present Privacy
            Policy
            of the mobile application (with all existing additions and changes). "User" means a legal entity or an
            individual who has downloaded a Mobile Application to a smartphone, tablet, watch or any other mobile device
            and/or has activated such a Mobile Application on one of these devices. "User Agreement" means an agreement
            concluded between the Copyright Holder and the User regarding the procedure, rules and features of the
            User's
            use of the Mobile Application. The User joins such an agreement and has no right to make and/or require any
            changes or additions to it. The User can read the terms of the User Agreement at the following link:
            https://www.hermitageshop.ru/warranty . "Copyright Holder" means the following person who owns the exclusive
            rights of ownership of the Mobile Application Arteсom LTD., TIN/IEC 7814121367/78400100, 191186, St.
            Petersburg,
            Dvortsovaya pl., 6-8, letter A, 1-N, room 205 "Cookies" means small files sent to any mobile applications or
            website and placed on smartphones, tablets, watches and other mobile devices of the User, to improve the
            operation of such applications or sites, as well as the quality of the content posted in them.
          </p>
          <div className={styles.Heading}>2. RELATIONS COVERED BY THE POLICY</div>
          <p>
            General provisions This Policy is used and applies exclusively to Personal Data received from the User in
            connection with the use of the Mobile Application. The provisions of this Policy are aimed at: (1)
            determination
            of the types and types of Personal Data received, directions and purposes of use (processing) Personal Data,
            as
            well as the sources of obtaining such Personal Data; and (2) determining the User's rights with respect to
            protecting the confidentiality of the Personal Data transmitted to them; and (3) identification of persons
            responsible for the processing and storage of Personal Data, as well as third parties to whom such data is
            disclosed (in whole or in part). The rules of this Policy do not apply in the case of processing by third
            parties of Personal Data that is voluntarily provided by the User. By installing and/or activating a Mobile
            Application on a smartphone, tablet, watch or other mobile device, the User agrees to the terms of this
            Policy
            and gives his consent to the Copyright Holder to collect, process, retain and store Personal Data in
            accordance
            with the procedure and conditions provided for in this Policy. If the User does not agree with the terms of
            the
            Policy and /or individual terms of the Policy are not clear to him, in this case the User is obliged to
            immediately stop using the Mobile Application. User's rights to protect personal data In connection with the
            provision of Personal Data, the User automatically receives the following rights: (1) to receive data
            concerning
            their processing (the grounds and purposes of such processing, the processing methods used, information
            about
            persons who have access to them or to whom they may be disclosed on the basis of a contract or Law). (2) to
            receive data on the location and identification data of persons who process Personal Data. (3) receive data
            on
            the terms of storage of Personal Data. (4) receive data on the trans-border transfer of Personal Data that
            has
            been carried out or is expected to be carried out. (5) to receive information about the location and
            identification data of persons who store Personal Data. (6) appeal the actions or omissions of the Copyright
            Holder to the authorized body for the protection of the rights of personal data subjects or in court. (7)
            receive compensation for damages and/or compensation for moral damage in court as a result of violations of
            the
            User's rights to the protection and protection of his Personal Data committed by the Copyright Holder and/or
            third parties. (8) exercise other rights in the field of personal data protection provided for by Law or the
            provisions of this Policy.
          </p>
          <div className={styles.Heading}>3. LIST OF PERSONAL DATA COLLECTED</div>
          <p>
            Non-personalized user information In connection with the use of the Mobile Application, the Copyright Holder
            can
            automatically collect and process the following non-personalized information about the User: (1) traffic
            information, the possible number of clicks made, logs and other data. (2) information about the device
            (identification number, mobile operator network) from which the login is performed, operating system,
            platform,
            browser type and other browser information, IP address. Personal data about users The User provides the
            Copyright Holder with the following personal data about himself: (1) full surname, first name and
            patronymic.
            (2) email address. (3) mobile phone number. (4) data from the following social network: Vkontakte. The
            Copyright
            Holder may collect, process and store the User ID of any of the social networks used by the User within the
            framework of the Mobile Application. If the User's registration in the Mobile Application is carried out
            using
            one of his accounts in social networks, the User thereby provides the Copyright Holder with automatic
            consent
            to
            the collection, processing and storage of Personal Data that became available to the Copyright Holder
            through
            the selected social network. (5) all photos, video clips, audio files and other types of media that are
            stored
            on the device from which you log in to the Mobile Application. (6) data contained in the personal account
            (profile) The User, all internal correspondence of the User (if any), as well as other activity of the
            User's
            personal account (profile). (7) data on orders made to Users/purchases and/or services received/paid for via
            the
            Mobile App. (8) data on all publications made by the User in the Mobile Application, including, but not
            limited
            to, comments, ratings, reviews, publication of reports, videos and photos, likes, ratings and/or any other
            forms
            of activity available to the User in the Mobile Application and/or created content. (9) data and information
            obtained as a result of combining certain Personal Data of a particular User, as well as data and
            information
            obtained about the User received from third parties (partners, marketers, researchers). The User is the only
            person responsible for the completeness of the personal (personal) data provided and is obliged to change
            them
            in a timely manner (update, check, correction) on a regular basis. The Copyright Holder assumes that all
            personal (personal) data provided by the User is reliable, and that the User maintains such information up
            to
            date. Information about transactions being made The user can make payments for goods or services through the
            Mobile Application by entering information about the payment card and the identification data of the owner
            of
            such a card into a special field. The user can make payments in the Mobile Application in the following way:
            •
            by bank card. • using the Pay Pal payment system. • using the Apple Pay payment system. • using the Google
            Pay
            payment system. • using the Your Payments payment system. • and also in the following way: From the mobile
            operator's account. In this case, the collection and processing of User data is carried out solely for the
            purposes of payment, fraud prevention, as well as compliance with other requirements of the Law. The User
            gives
            his consent to access and collection by the Copyright Holder and the relevant payment system or banking
            institution through which the payment is made to such Personal Data, and also agrees to the privacy policy
            of
            the relevant payment system or banking institution. Use of cookies This Mobile Application uses certain
            cookies
            to store the IP address, User preferences or the type of device used in order to (1) maintain statistics of
            visits and traffic to the site, and (2) personalize the data displayed on the User's screen, and (3) store
            data
            necessary for User identification, including when accessing from different devices, and (4) display ads
            according to the User's interests and preferences. The mobile application can use both its own cookies
            belonging
            to the Copyright Holder and Cookies of third parties. The mobile application uses the following cookies: (1)
            Technical (functional) cookies that are needed to control traffic and data transmission, to identify Users
            and
            provide access to the content of the Mobile Application to the User and without which the use of the Mobile
            Application is functionally limited, as well as to prevent the provision of recommendations that do not
            correspond to the interests of the User.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Policy;
