// Libraries
import classnames from 'classnames';

// Hooks
import { useSetCommonCountCart } from './hooks';

// Assets
import minusIcon from '../../assets/actions/minus.svg';
import plusIcon from '../../assets/actions/plus.svg';

// Components
import { Loader } from '../../components/Loader';

// Styles
import styles from './style.module.scss';
import { formatPrice } from '../../helpers/formatPrice';
import {useSelector} from "react-redux";

const DEFAULT_LIMITS = {
  min: 1,
  max: 999,
};

const Counter = ({
  limits = DEFAULT_LIMITS,
  product,
  showPrice = true,
  row
}) => {
  const { count, subtractionCount, increaseCount, setCount, loading } = useSetCommonCountCart(product);
  const canToSetLowest = count > limits.min && product?.product?.stockStatus?.name !== 'Предзаказ' && product?.stockStatus?.name !== 'Предзаказ';
  const canToSetHighest = count < limits.max;
  const promoData = useSelector(store => store?.promoReducer?.data);
  const currentOption = product?.productOptionValue || null;
  const price = currentOption?.price || product?.price;
  const special_price = currentOption?.special_price || product?.special_price;

  const couponPrice = promoData?.coupon
    && promoData.coupon?.discount
    && promoData?.products
    && promoData?.coupon?.type === 'present'
    && promoData.products.some(({ product_id, option_id }) =>
      (product?.product_id || product?.productId).toString() === product_id.toString()
      && (product?.productOptionValue && option_id
        ? product.productOptionValue.id.toString() === option_id.toString()
        : true
      )
    )
      ? (special_price ?? price) * (1 - promoData.coupon.discount / 100)
      : null;

  const isOverflowedLimit =
    currentOption
      ? product?.option?.quantity_selected > product.quantity
      : product?.quantity_selected > product.quantity;

  const subHandler = () => {
    if (canToSetLowest) {
      subtractionCount();
    }
  };

  const plusHandler = () => {
    if (canToSetHighest) {
      increaseCount();
    }
  };

  if(loading) {
    return <Loader />;
  }

  const Price = () => showPrice && product ? (
    <>
      <div className={classnames({
        [styles.Price]: true,
        [styles.NotAvailable]: isOverflowedLimit && product?.product?.stockStatus?.name !== 'Предзаказ' && product?.stockStatus?.name !== 'Предзаказ'
      })}>
        {formatPrice((couponPrice ?? special_price ?? price) * product.quantity_selected || 0)}
      </div>
      {special_price && special_price !== price && !couponPrice && (
        <div className={classnames({
          [styles.Price]: true,
          [styles.NotAvailable]: isOverflowedLimit && product?.product?.stockStatus?.name !== 'Предзаказ' && product?.stockStatus?.name !== 'Предзаказ',
          [styles.OldPrice]: special_price
        })}>
          {formatPrice(price * product.quantity_selected || 0)}
        </div>
      )}
    </>
  ) : <></>;

  const renderSignCount = () => {
    if (product?.stockStatus?.name === 'Предзаказ' || product?.product?.stockStatus?.name === 'Предзаказ') return ''

    return count === limits.max || limits.max === 0
      ? 'Больше нет'
      : count > limits.max
        ? (
          <div className={styles.Remainder_action} onClick={() => setCount(limits.max)}>
            Уменьшить кол-во до {limits.max} шт
          </div>
        ) : count < limits.max
          ? '' // `Осталось ${limits.max - count}\nшт`
          : 'Удалите товар'
  }

  const renderCount = () => {
    if (product?.stockStatus?.name === 'Предзаказ' || product?.product?.stockStatus?.name === 'Предзаказ') return 1;

    return product.quantity_selected;
  };

  const CounterContent = () => (
    <div className={classnames(styles.Wrap, !showPrice && styles.Wrap_one)}>
      <div
        className={classnames({
          [styles.Counter]: true,
          [styles.NotAvailable]: isOverflowedLimit
        })}
      >
        <div
          className={classnames(styles.Button, !canToSetLowest && styles.Button_disabled)}
          onClick={subHandler}
        >
          <img src={minusIcon} alt="sub" />
        </div>
        <div
          className={classnames({
            [styles.Value]: true,
            [styles.NotAvailable]: isOverflowedLimit
          })}
        >
          {renderCount()}
        </div>
        <div
          className={classnames({
            [styles.Button]: true,
            [styles.Button_disabled]: !canToSetHighest
          })}
          onClick={plusHandler}
        >
          <img src={plusIcon} alt="plus" />
        </div>
      </div>
      <div
        className={classnames({
          [styles.Remainder]: true,
          [styles.Remainder_row]: row,
        })}
      >
        {renderSignCount()}
      </div>
    </div>
  );

  return (
    <>
      <Price />
      <CounterContent />
    </>
  );
};

export default Counter;
