import { SET_POPUP, CHANGE_POPUP_STATS, SET_POPUP_STATS } from '../actions';

const initialState = null;

export const popupReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_POPUP: {
			return {
				...state,
				popup: action.payload
			}
		}

		case SET_POPUP_STATS:
			return {
				...state,
				popupStats: action.payload
			}

		case CHANGE_POPUP_STATS: {
			return {
				...state,
				popup: {
					...state.popup,
					stats: {
						...state.popup.stats,
						...action.payload
					}
				}
			}
		}

		default:
			return state
	}
};
