export function getPopup(popups, popUpPage) {
  const popupStatsFromLocalStore = localStorage.getItem('hermitage_popup_stats');

  if (!popupStatsFromLocalStore && popups.length > 0) {
    const popupStats = {
      'one': [],
      'more': []
    }

    localStorage.setItem('hermitage_popup_stats', JSON.stringify(popupStats));

    return popups[0];
  }

  if (popups.length > 0) {
    const popupStats = JSON.parse(popupStatsFromLocalStore);
    let activeModal = null;

    for (const popup of popups) {
      if (popup.number_impressions === 'one' && !popupStats.one.includes(popup.id) && popup.page === popUpPage) {
        activeModal = popup;
        break;
      }

      if (popup.number_impressions === 'more' && !popupStats.more.includes(popup.id) && popup.page === popUpPage) {
        activeModal = popup;
        break;
      }
    }

    return activeModal;
  }

  return null;
}
