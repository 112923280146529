// Libraries
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import { composeWithDevTools } from 'redux-devtools-extension'

import { footerReducer, mainReducer, productsReducer, faqReducer, excursionsReducer, popupReducer, promoReducer } from './reducers';

// Components
import App from './App';

// Constant
import { URI, USER_COOKIE } from './constants';

// Styles
import './index.css';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const apolloHttpLink = createHttpLink({
  uri: `${URI}/api/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const user = Cookies.get(USER_COOKIE) ? JSON.parse(Cookies.get(USER_COOKIE)) : null;
  return {
    headers: {
      ...headers,
      authorization: user?.access_token ? `bearer ${user.access_token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(apolloHttpLink),
  cache: new InMemoryCache(),
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#CF086E',
    },
    secondary: {
      main: '#CF086E',
    },
  },
});

// TODO: перенести в отдельный файл

export const signIn = (loginData) => ({
  type: 'SIGN_IN',
  loginData,
});

export const logout = () => ({
  type: 'LOGOUT',
});

// reducers.js
const userData = (
  state = {
    user: null,
    token: null,
  },
  action,
) => {
  switch (action.type) {
    case 'SIGN_IN':
      return action.user;
    case 'LOGOUT':
      return {
        user: null,
        token: null,
      };
    default:
      return state;
  }
};

const editorPages = (state = [], action) => {
  switch (action.type) {
    case 'EDITOR_SET_PAGES':
      return [...action.pages];
    case 'EDITOR_ADD_PAGE':
      return [action.page, ...state];
    case 'EDITOR_REMOVE_PAGE':
      return [...state.filter((page) => page.id !== action.page.id)];
    case 'EDITOR_CHANGE_PAGE':
      return [...state.map((page) => (page.id === action.page.id ? action.page : page))];
    case 'EDITOR_LOGOUT':
      return [];
    default:
      return state;
  }
};

const reducers = combineReducers({
  userData,
  editorPages,
  mainReducer,
  footerReducer,
  productsReducer,
  faqReducer,
  promoReducer,
  excursionsReducer,
  popupReducer
});

const composedEnhancers = composeWithDevTools();

const configureStore = () => {
  return createStore(
    reducers,
    composedEnhancers
  );
};

const store = configureStore();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);
