import { useContext, useEffect } from 'react';
import { Form } from 'react-final-form';
import { animateScroll as scroll } from 'react-scroll';
import { Input } from '../../../components/Input/Input';
import { RadioGroup } from '../../../components/RadioGroup/RadioGroup';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import Link from '../../../components/Link';
import Button from '../../../components/Button';
import { ErrorPopup } from '../../../components/ErrorPopup';
import { useRegisterCustomer } from '../hooks';
import { isEmptyObject } from '../../../helpers';
import { registerValidation } from '../helpers';
import { BANNER_VARIANTS, ERRORS_RU_TRANSLATIONS } from '../../../constants';
import { BannerContext } from "../../../components/Page";
import styles from '../style.module.scss';

const initialValues = {
  birthday: null,
};


const ERRORS = Object.assign({}, ERRORS_RU_TRANSLATIONS, {
  'Validation error': 'Проверьте, правильно ли вы заполнили поля'
});

const options = {
  duration: 500,
  delay: 100,
  smooth: true
};


export const Register = () => {
  const { handlerRegisterCustomer, error, removeError } = useRegisterCustomer();
  const setBannerData = useContext(BannerContext);

  useEffect(() => {
    if (error) {
      scroll.scrollToTop(options);
    }
  }, [error]);

  return (
    <>
      {error && <ErrorPopup
        text={ERRORS[error] || error}
        handlerClose={removeError}
        style={{ marginBottom: 20 }}
      />}
      <Form
        initialValues={initialValues}
        onSubmit={handlerRegisterCustomer}
        validate={registerValidation}
        render={({ handleSubmit, submitting, pristine, errors, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Input
                name={'customerNameAndLastName'}
                type={'text'}
                placeholder="Имя и фамилия"
                label={'Имя и фамилия'}
              />
              <RadioGroup
                data={[
                  {
                    label: 'Мужской пол',
                    value: 'man',
                  },
                  {
                    label: 'Женский пол',
                    value: 'woman',
                  },
                ]}
                name={'sex'}
                label={'Пол'}
              />
              <DatePicker placeholder="Дата рождения" maxDate={new Date()} name={'birthday'} value={values.birthday}/>
              <Input placeholder="E-mail" name={'email'} type={'text'} label={'E-mail'} />
              <Input placeholder="Пароль" name={'password'} type="password" label={'Пароль'} />
              <Input
                placeholder="Повторите пароль"
                name={'password_confirmation'}
                type="password"
                label={'Повторите пароль'}
              />
              <Checkbox
                name={'personal_data'}
                label={
                  <>
                    Я прочел/ла условия &nbsp;
                    <span onClick={() => setBannerData({ variant: BANNER_VARIANTS.process_personal_data })}>
                      <Link alwaysLighting>
                        Обработки персональных данных
                      </Link>
                    </span>
                  </>
                }
              />
              <Button
                htmlType='submit'
                filled
                className={styles.Button}
                disabled={submitting || pristine || !isEmptyObject(errors)}
              >
                Зарегистрироваться
              </Button>
            </form>
          );
        }}
      />
    </>
  );
};
