
import { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import Button from '../Button';
import useGetPopup from '../../pages/Landing/hooks/useGetPopup';
import { getPopup } from './helpers';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import styles from './style.module.scss';

function clearStore() {
	const popupStats = JSON.parse(localStorage.getItem('hermitage_popup_stats'));

	if (popupStats?.more) {
		popupStats.more = [];
		localStorage.setItem('hermitage_popup_stats', JSON.stringify(popupStats));
	}
}

const PopUp = ({ page }) => {
	const { popupData } = useGetPopup({ page });
	let activePopup = getPopup(popupData, page);
	const [isShowPopup, setIsShowPopup] = useState(false);

	useEffect(() => {
		setIsShowPopup(!!activePopup);
	}, [activePopup]);

	useEffect(() => {
		document.addEventListener("DOMContentLoaded", clearStore);

		return () => {
			clearStore();
			document.removeEventListener("DOMContentLoaded", clearStore);
		}
	}, []);

	const handleClose = (popup) => () => {
		const popupStats = JSON.parse(localStorage.getItem('hermitage_popup_stats'));
		const ids = popupStats[popup.number_impressions];

		if (!ids.includes(popup.id)) {
			popupStats[popup.number_impressions].push(popup.id);
		}

		localStorage.setItem('hermitage_popup_stats', JSON.stringify(popupStats));

		activePopup = getPopup(popupData, page);

		setIsShowPopup(false);
	};

	return (
		<Modal
			open={isShowPopup}
			onClose={handleClose(activePopup)}
			className={styles.PopUp}
		>
			<div className={styles.Container}>
				<CloseIcon
					className={styles.CloseIcon}
					onClick={handleClose(activePopup)}
				/>
				<div className={styles.Title}>
					{activePopup?.title}
				</div>
				<div className={styles.Description}>
					{activePopup?.description}
				</div>
				<Button
					filled
					className={styles.Button}
					onClick={handleClose(activePopup)}
				>
					Закрыть
				</Button>
			</div>
		</Modal>
	)
};

export default PopUp;