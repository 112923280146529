// Libraries
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

// Components
// import Link from '../../Link';

// Actions 
import { setFilterGroups, setKeyword } from '../../../actions'

// Hooks
import { useCategories } from '../../../hooks';

import styles from '../style.module.scss';
import { useActiveCategory } from '../../HeaderCatalog/hooks';

export const Links = ({ hasBlackTheme, isFaded }) => {
  const { catalogCategories } = useCategories();
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectCategories } = useActiveCategory();

  const onChoseCategory = (category) => () => {
    selectCategories(category)();
    dispatch(setFilterGroups(category.filterGroup?.map(item => parseInt(item.id))));
    // dispatch(setFilters(item.filterGroup));
    dispatch(setKeyword(null));
    const url = `/catalog/${category.seo_url || category.name}/${category.children[0].seo_url || category.children[0].name}`.toLowerCase().replace(/ /g, '_');
    history.push(url);
  };

  return catalogCategories.slice(0, 4).map((item) => (
    <div
      key={item.id}
      className={classNames(styles.Link, {
        [styles.BlackTheme]: hasBlackTheme,
        // [styles.selected]: isFaded || [item.name.toLowerCase().replace(/ /g, '_'), item.seo_url].includes(categoryName)
      })}
      onClick={onChoseCategory(item)}
    >
      {item.name}
    </div>
  ));
};
