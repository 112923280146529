// Libraries
import { useMemo } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Service
import history from './history';

// Pages
import Landing from './pages/Landing';
import Search from './pages/Search';
import Product from './pages/Product';
import Tickets from './pages/Tickets';
import About from './pages/About';
import PersonalArea from './pages/PersonalArea';
import Faq from './pages/Faq';
import Basket from './pages/Basket';
import Ordering from './pages/Ordering';
import Catalog from './pages/Catalog';
import OrderAccepted from './pages/OrderAccepted';
import OrderRejected from './pages/OrderRejected';
import Auth from './pages/Auth';
import NotFound from './pages/404';
import Excursions from './pages/Excursions';
import Terms from './pages/Terms';

// Styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-circular-progressbar/dist/styles.css';
import Policy from './components/Banner/Policy';

const ROUTES = [
  {
    path: '/search',
    component: Search,
  },
  {
    path: '/terms',
    component: Terms
  },
  {
    path: '/product/:id',
    component: Product,
  },
  {
    path: '/tickets',
    component: Tickets,
  },
  {
    path: '/excursions',
    component: Excursions,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/lk',
    component: PersonalArea,
  },
  {
    path: '/faq',
    component: Faq,
  },
  {
    path: '/basket',
    component: Basket,
  },
  {
    path: '/ordering',
    component: Ordering,
  },
  {
    path: '/catalog/:category/:subCategory',
    component: Catalog,
  },
  {
    path: '/catalog/:category',
    component: Catalog,
  },
  {
    path: '/catalog',
    component: Catalog,
  },
  {
    path: '/order_accepted',
    component: OrderAccepted,
  },
  {
    path: '/order_rejected',
    component: OrderRejected,
  },
  {
    path: '/auth',
    component: Auth,
  },
  {
    path: '/policy',
    component: Policy
  }
];

const App = () => {
  const renderRoutes = useMemo(() => ROUTES.map((route) => (
    <Route key={route.path} path={route.path} component={route.component} />
  )), []);

  return (
    <BrowserRouter history={history} basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/" component={Landing} />
        {renderRoutes}
        <Route exact path="" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
