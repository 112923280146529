import Cookies from 'js-cookie';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { setProductCart } from '../actions';
import { selectAllProductsFromCart } from '../selectors';
import { useEffect } from 'react';
import { USER_COOKIE } from '../constants';
import { useGetProduct } from './useGetProduct';
import { useProducts } from "./useProducts";

const getCustomerCart = loader('../graphql/getProductCart.graphql');

export const useCart = () => {
  const dispatch = useDispatch();
  const { products } = useProducts();
  const cartProducts = useSelector(selectAllProductsFromCart);
  const userCookie = Cookies.get(USER_COOKIE);
  const localeCart = null;
  const [getProductCartQuery, { loading }] = useLazyQuery(getCustomerCart, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      if (res?.getProductCart) {
        dispatch(setProductCart(res.getProductCart));
      }
    },
  });
  const { handleGetProduct } = useGetProduct();

  const getProductCart = () => {
    if (localeCart) {
      dispatch(setProductCart(JSON.parse(localeCart)));
    } else if (userCookie) {
      getProductCartQuery();
    } else {
      cartProducts.map(({ id }) => handleGetProduct(id))
    }
  }

  useEffect(() => {
    getProductCart();
  }, [userCookie]);

  useEffect(() => {
    const updatedCart = cartProducts.map(product => products.get(product.id) ? {
      ...product,
      ...products.get(product.id),
      option_value_id: product?.option_value_id,
    } : product);

    dispatch(setProductCart(updatedCart));
  }, [dispatch, products]);

  return {
    products: cartProducts,
    getProductCart,
    cartLoading: loading,
    loading,
  };
};
