import styles from '../DeliveryAndPayment/style.module.scss';

function AboutExcursion() {
  return (
    <div>
      <div className={styles.Title}>Как использовать</div>
      <div>
        <p>Покупку экскурсии можно осуществить как в приложении, так и на сайте Эрмитажного Магазина.</p>
        <p>Для покупки на сайте потребуется личный кабинет. После создания личного кабинета и осуществлении покупки необходимо авторизоваться в приложении Аудиогид по Эрмитажу (используя эл. почту и пароль, указанные при регистрации на сайте);</p>

        <p>В случае, если после покупки на сайте экскурсия не разблокировалась в приложении, пожалуйста:</p>
        <p>- Перейдите в меню приложения (левый верхний угл) -> О приложении</p>
        <p>- Кликните «Восстановить покупки»</p>
        <p>- Перезапустите приложение;</p>

        <p>В случае, если при покупки экскурсии Вы использовали авторизацию через
          Google/VK/iOS, при запуске приложения, пожалуйста, воспользуйтесь функцией "Забыли пароль" - на вашу эл. почту будет отправлен новый пароль, который можно использовать для авторизации в приложении.</p>
      </div>
    </div>)
}

export default AboutExcursion;