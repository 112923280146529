// Libraries
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

// Hooks
import { usePromocode } from '../../hooks';

// Actions
import { SET_PROMO } from '../../actions/setPromo';
import { setPromoError, setPromoIsUsed, setPromoValue } from '../../actions';

// Selectors
import { selectPromoError, selectPromoIsUsed, selectPromoValue } from '../../selectors';

// Assets
import { ReactComponent as CloseIcon } from '../../assets/close_red.svg';

// Styles
import styles from './style.module.scss';

const PromoCodeInput = ({ products, containerClasses = [], inputProps = {} }) => {
  const dispatch = useDispatch();
  const { onUsePromocode } = usePromocode();
  const [loading, setLoading] = useState(false);
  const promoError = useSelector(selectPromoError);
  const promoIsUsed = useSelector(selectPromoIsUsed);
  const promoValue = useSelector(selectPromoValue);

  const handleChange = (e) => {
    dispatch(setPromoValue(e.target.value));
  };

  const handleCheck = () => {
    if (promoValue.length === 0) {
      dispatch(setPromoError('Пожалуйста, введите промокод'));
    } else {
      setLoading(true);
      onUsePromocode({
        productAndOptions: products.map(({ productOptionValue, productId, product_id, option_value_id }) => ({
          product_id: product_id || productId,
          ...(productOptionValue ? { option_id: productOptionValue.id } : { option_id: option_value_id }),
        })),
        code: promoValue,
      }).then((res) => {
        const promo = res?.data?.usePromoCode;
        dispatch({ type: SET_PROMO, payload: promo });
        setLoading(false);

        if (!promo?.status || promoError) {
          dispatch(setPromoIsUsed(false));
        } else {
          dispatch(setPromoIsUsed(true));
          dispatch(setPromoError(null));
        }
      });
    }
  };

  const onResetCoupone = () => {
    dispatch(setPromoError(null));
    dispatch(setPromoValue(''));
    dispatch({ type: SET_PROMO, payload: null });
    dispatch(setPromoIsUsed(false));
  };

  return (
    <div className={classnames(styles.Promo, ...containerClasses)}>
      <div className={classnames(styles.Actions, promoError && styles.Actions_error)}>
        <input
          value={promoValue}
          onChange={handleChange}
          onKeyUp={(e) => e.key === 'Enter' && handleCheck()}
          placeholder="Введите промокод"
          disabled={loading || promoIsUsed}
          style={{ width: '100%' }}
          {...inputProps}
        />
        {loading ? (
          <CircularProgress className={styles.Loader} />
        ) : !promoIsUsed ? (
          <div
            className={classnames(styles.Action, promoValue.length === 0 && styles.Action_disabled)}
            onClick={handleCheck}
          >
            Применить
          </div>
        ) : (
          <CloseIcon onClick={onResetCoupone} className={styles.CloseIcon} />
        )}
      </div>
      {!!promoError && <div className={styles.ErrorMessage}>{promoError}</div>}
      {promoIsUsed && <div className={styles.AcceptedCode}>Промокод {promoValue} активирован!</div>}
    </div>
  );
};

export default PromoCodeInput;
