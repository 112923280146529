import {useEffect, useRef, useState} from "react";
import { URI } from "../../constants";
import {useSelector} from 'react-redux';
import {selectAllProductsFromCart} from '../../selectors';

export const useCDEK = () => {
  const [pvz, setPvz] = useState(null);
  const cdekRef = useRef();
  const cartProducts = useSelector(selectAllProductsFromCart);

  // eslint-disable-next-line no-undef
  const [CDEK, setCDEK] = useState(null);

  useEffect(() => {
    if (CDEK || cartProducts.length === 0) return;

    if (window.ISDEKWidjet) {
      setCDEK(
        new window.ISDEKWidjet({
          defaultCity: 'Санкт-Петербург', // какой город отображается по умолчанию
          cityFrom: 'Санкт-Петербург', // из какого города будет идти доставка
          country: 'Россия', // можно выбрать страну, для которой отображать список ПВЗ
          link: 'forpvz', // id элемента страницы, в который будет вписан виджет
          path: `${URI}/api/scripts/`, // директория с библиотеками
          servicepath: `${URI}/api/sdek_service`, // ссылка на файл service.php на вашем сайте
          templatepath: `${URI}/api/sdek_template`,
          hidedelt: true,
          hidecash: true,
          hidedress: true,
          choose: true,
          onChoose: (wat) => {
            if (cdekRef.current) {
              const burger = cdekRef.current.querySelector('.CDEK-widget__sidebar-burger');
              burger.click();
              setPvz(wat);
            }
          },
          goods: cartProducts.map((product) => (
            {
              length: product?.product ? String(product.product.length) : product?.length ? String(product?.length) : '0.1',
              width: product?.product ? String(product.product.width) : product?.width ? String(product?.width) : '0.1',
              height: product?.product ? String(product.product.height) : product?.height ? String(product?.height) : '0.1',
              weight: product?.product ? String(product.product.weight) : product?.weight ? String(product?.weight) : '0.1'
            })),
        })
      )
    }
  }, [CDEK, cartProducts]);

  return {
    CDEK,
    pvz,
    cdekRef,
  };
}; 