// Libraries
import { useMemo } from 'react';
import classnames from 'classnames';

// Components
import SlickSlider from 'react-slick';
import Button from '../Button';

// Hooks
import { useResize } from '../../constants';

// Styles
import styles from './style.module.scss';


const LandingSlider = ({ data }) => {
  const { isTablet, isMobile } = useResize();

  const settings = {
    autoplay: true,
    autoplaySpeed: 15000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    pauseOnHover: false,
    lazyLoad: true,
    cssEase: 'linear',
    appendDots: (dots) => (
      <>
        <div className={styles.Slider__Dots}>
          <div className="slick-dots">{dots}</div>
        </div>
      </>
    ),
  };

  const renderContent = useMemo(() => {
    return data.map(({
      mobile_image,
      image,
      name,
      buttonText = 'Посмотреть',
      url,
      stretch_image,
      shadow,
      button_type
    }) => (
      <div key={name} className={styles.Slide}>
        <div className={classnames(styles.Slide__Background, !stretch_image && styles.Slide__Background_contain)}>
          <img src={isMobile || isTablet ? mobile_image : image} alt="" />
        </div>
        <div
          className={classnames(
            styles.Slide__Content,
            shadow && styles.Slide__Content_withShadow
          )}
        >
          <div className={styles.Slide__Title}>{name}</div>
          <Button
            linkClassName={styles.Slide__Button}
            link={(url && url.split('.ru')[1]) || '/'}
            theme={button_type || 'white'}
            filled
          >
            {buttonText}
          </Button>
        </div>
      </div>
    ));
  }, [data]);

  return (
    <div className={styles.Slider} id='slider'>
      <SlickSlider {...settings}>
        {renderContent}
      </SlickSlider>
    </div>
  );
};

export default LandingSlider;
