// Libraries
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

// Assets
import { ReactComponent as BasketIcon } from '../../assets/actions/basket.svg';

// Components
import Counter from '../Counter';
import { Offer } from './components';
import { Loader } from '../Loader';

// Hooks
import { useDeleteProductFromCart } from '../../hooks';

// Constants
import { BASKET_ITEM_TYPES, TICKET_TYPES, useResize } from '../../constants';

// Styles
import styles from './style.module.scss';
import classnames from 'classnames';


const BasketItem = ({ product, events, onChangeSum }) => {
  const {
    deleteProductFromCartHandler, 
    loading: deleteLoading 
  } = useDeleteProductFromCart();
  const { isLargeMobile } = useResize();
  const isOffer = product?.type === BASKET_ITEM_TYPES.offer;
  const category = product?.product?.productToCategories[0]?.name || product?.product?.productToCategories[0]?.categoryDescriptions?.name || null;
  const categoryAlt = product?.productToCategories?.length > 0 ? product?.productToCategories[0]?.name || product?.productToCategories[0]?.categoryDescriptions?.name : null;
  const stockStatus = product?.product?.stockStatus || product?.stockStatus;
  const option = Object.prototype.hasOwnProperty.call(product, 'option_value_id')
    ? product?.productOptionValues?.find(({ id }) => id === product?.option_value_id?.toString())
    : null;
  const limit = option?.quantity || product.quantity;
  const isOverflowedLimit = stockStatus?.name !== 'Предзаказ' && (product?.option?.quantity_selected > limit || product?.quantity_selected > limit);

  const onRemoveFromCart = () => {
    deleteProductFromCartHandler(product?.product_id || product?.productId, product?.option_id);
  };

  const renderContent = useMemo(() => {
    if (isOffer) {
      return <Offer product={product} events={events} />;
    }

    const option =
      product.option_id && product.option_name
        ? product.option_name
        : null;

    const optionTitle = product?.productOptionValue?.name ? ` "${product.productOptionValue.name}"` : product?.option_name ? ` "${product?.option_name}"` : '';

    const renderSignLimit = () => {
      if (product?.product?.stockStatus?.name === 'Предзаказ' || product?.stockStatus?.name === 'Предзаказ') return 'Предзаказ';

      return !limit
        ? 'Нет в наличии'
        : isOverflowedLimit
          ? 'Выбрано слишком много товаров'
          : ''
    }

    return (
      <div className={styles.Row}>
        <div className={styles.Specifications}>
          <div className={styles.Category}>
            {category || categoryAlt}
          </div>
          <Link to={`/product/${product?.product_id || product?.productId}`} className={styles.Name}>
            {product?.product?.productDescriptions?.name
              || product?.name
              || product?.productDescriptions?.name}
            {optionTitle}
          </Link>
          <div className={styles.NotAvailable}>
            {renderSignLimit()}
          </div>
        </div>
        {!isLargeMobile && (
          <div className={styles.Actions}>
            <Counter
              onChangeSum={onChangeSum}
              product={product}
              limits={{
                min: (option?.quantity || product?.quantity) === 0 ? 0 : 1,
                max: (option?.quantity || product?.quantity) === 0 ? 0 : limit,
              }}
            />
            <div
              className={
                classnames({
                  [styles.Remove]: true,
                  [styles.Remove_withAlert]: limit === 0 && stockStatus?.name !== 'Предзаказ'
                })
              }
              onClick={onRemoveFromCart}
            >
              <BasketIcon />
              {limit === 0 && stockStatus?.name !== 'Предзаказ' &&  (
                <div className={styles.Remove__Alert}>
                  !
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }, [isOffer, isLargeMobile, product, events]);
  
  return (
    <div
      className={classnames(
        styles.BasketItem,
        isOverflowedLimit && styles.BasketItem_notInStock,
        deleteLoading && styles.BasketItem_centered
      )}
    >
      {deleteLoading ? <Loader /> : (
        <>
          <div className={styles.Card}>
            <div className={styles.Preview}>
              <img src={product?.productOptionValue?.image || product?.product?.image || product?.image} alt="" />
            </div>
            {renderContent}
          </div>
          {product?.type === BASKET_ITEM_TYPES.ticket && (
            <div className={styles.Info}>
              <div className={styles.Info__Col}>
                <div className={styles.Info__Title}>Дата и время</div>
                <div className={styles.Info__Value}>17.10.21 в 16:30</div>
              </div>
              {Object.entries(product.types).map(
                ([type, value]) =>
                  value > 0 && (
                    <div className={styles.Info__Col}>
                      <div className={styles.Info__Title}>{TICKET_TYPES[type]}</div>
                      <div className={styles.Info__Value}>{value}</div>
                    </div>
                  ),
              )}
            </div>
          )}
          {isLargeMobile && (
            <div className={styles.Actions}>
              <Counter
                onChangeSum={onChangeSum}
                product={product}
                limits={{
                  min: product?.quantity === 0 ? 0 : 1,
                  max: product?.quantity === 0 ? 0 : limit,
                }}
              />
              <div
                className={styles.Remove}
                onClick={onRemoveFromCart}
              >
                <BasketIcon />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BasketItem;
