// Libraries
import { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Slider from 'react-slick';

// Actions 
import { setFilterGroups, setKeyword } from '../../actions'

// Components
import Divider from '../Divider';
import CategoryCard from '../CategoryCard';

// Assets
import PrevArrow from '../Arrows/PrevArrow';
import NextArrow from '../Arrows/NextArrow';
import SelectArrow from '../../assets/components/SelectArrow';

// Hooks
import { useCategories } from '../../hooks';

// Styles
import styles from './style.module.scss';
import Link from '../Link';

const settings = {
  infinite: false,
  touchMove: true,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 8,
  lazyLoad: true,
  prevArrow: <PrevArrow arrowClassName={styles.PrevArrow} />,
  nextArrow: <NextArrow arrowClassName={styles.NextArrow} />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
      arrows: false,
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
      arrows: false,
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
      arrows: false,
    },
  ],
};

const RowCategories = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { category: categoryName } = useParams();
  const { categories } = useCategories();
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (categoryName) {
      const categoryData = categories.find(({ seo_url, name }) => [name.toLowerCase().replace(/ /g, '_'), seo_url && seo_url.toLowerCase().replace(/ /g, '_')].includes(categoryName));
      setItems(categoryData?.children.map((i) => ({ ...i, categoryName: categoryData.name })));
    } else {
      categories.map((category) => {
        setItems((prevArr) => [...prevArr, ...category?.children.map((c) => ({ ...c, categoryName: category.name }))]);
      });
    }
  }, [categoryName, categories]);

  const onClickToCategory = useCallback((data) => () => {
    if (data) {
      const categoryData = categories.find(({ children }) =>
        Array.isArray(children) && children.find(({ id }) => id === data?.id)
      );
      dispatch(setFilterGroups(categoryData?.filterGroup?.map(item => parseInt(item.id))));
      dispatch(setKeyword(null));

      if (categoryData) {
        history.push(`/catalog/${categoryData?.seo_url || categoryData.name}/${data?.seo_url || data.name}`.toLowerCase().replace(/ /g, '_'));
      } else {
        history.push(`/catalog/${data?.seo_url || data.name}`.toLowerCase().replace(/ /g, '_'));
      }
    }
  }, []);

  const renderCategories = useMemo(() => items?.sort((a, b) => {
    return a.name > b.name ? 1 : -1
  }).map((item) => {
    return (
      <CategoryCard
        onClick={onClickToCategory(item)}
        key={item.id}
        item={item}
        classes={{ card: styles.RowCategories__Card, label: styles.RowCategories__CardLabel }}
    />
    )
  }), [items]);

  const BackButton = useCallback(() => (
    <Link to='/catalog' className={styles.Back}>
      <div className={styles.Back__Icon}>
        <SelectArrow color='#CF086E' />
      </div>
      Все категории
    </Link>
  ), []);

  return (
    <>
      {categoryName && <BackButton />}
      <div className={styles.RowCategories}>
        {categories && categories.length > 0 && (
          <Slider {...settings}>
            {renderCategories}
          </Slider>
        )}
      </div>
      <Divider />
    </>
  );
};

export default RowCategories;
